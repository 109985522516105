var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticStyle: { "margin-top": "5px" } }, [
        _c(
          "div",
          { staticClass: "button-click-left" },
          [
            _c("el-date-picker", {
              staticClass: "button-left",
              attrs: {
                type: "date",
                "value-format": "yyyy-MM-dd",
                format: "dd-MM-yyyy",
                placeholder: "Chọn ngày",
                clearable: true,
              },
              on: {
                change: function ($event) {
                  return _vm.searchHeaderMethod()
                },
              },
              model: {
                value: _vm.dataSearch.date,
                callback: function ($$v) {
                  _vm.$set(_vm.dataSearch, "date", $$v)
                },
                expression: "dataSearch.date",
              },
            }),
            _c(
              "el-select",
              {
                staticClass: "button-left",
                attrs: { placeholder: "Tất cả", clearable: "" },
                on: {
                  change: function ($event) {
                    return _vm.searchHeaderMethod()
                  },
                },
                model: {
                  value: _vm.dataSearch.albumType,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearch, "albumType", $$v)
                  },
                  expression: "dataSearch.albumType",
                },
              },
              _vm._l(_vm.albumTypesOption, function (item) {
                return _c("el-option", {
                  key: item,
                  attrs: { label: item, value: item },
                })
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-row",
        {
          staticClass: "demo-image__placeholder",
          staticStyle: {
            width: "100%",
            "margin-top": "10px",
            border: "1px solid rgb(96 99 102 / 19%)",
            "overflow-y": "scroll",
            "max-height": "730px",
            "padding-left": "50px",
          },
        },
        _vm._l(_vm.albumResponseList, function (albumResponse) {
          return _c(
            "div",
            {
              key: albumResponse,
              staticClass: "inline-block",
              staticStyle: {
                float: "left",
                margin: "14px",
                border: "1px solid #dcdfe6",
                width: "223px",
                color: "#606266",
                position: "relative",
              },
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  staticStyle: { "text-decoration": "none" },
                  attrs: {
                    to: { path: "/parent/album-detail/" + albumResponse.id },
                  },
                },
                [
                  !albumResponse.albumNew
                    ? _c(
                        "span",
                        {
                          staticStyle: {
                            background: "orange",
                            position: "absolute",
                            "z-index": "1",
                          },
                        },
                        [_vm._v("Mới")]
                      )
                    : _vm._e(),
                  albumResponse.urlPictureFirst != null
                    ? _c("el-image", {
                        staticClass: "el-image-opacity",
                        staticStyle: { width: "100%", height: "200px" },
                        attrs: {
                          src: albumResponse.urlPictureFirst.replace(
                            "/Albumanh/",
                            "/Albumanh/thumbnail_"
                          ),
                        },
                      })
                    : _vm._e(),
                  _c(
                    "h4",
                    {
                      staticClass: "demonstration",
                      staticStyle: {
                        display: "-webkit-box",
                        "-webkit-box-orient": "vertical",
                        "-webkit-line-clamp": "1",
                        color: "#30313499",
                        "text-align": "left",
                        "margin-left": "5px",
                        overflow: "hidden",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(albumResponse.albumName) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "demonstration",
                  staticStyle: { float: "right", "margin-right": "10px" },
                },
                [_vm._v(_vm._s(albumResponse.albumType))]
              ),
              _c(
                "span",
                {
                  staticClass: "demonstration",
                  staticStyle: { "margin-right": "5px", float: "right" },
                },
                [
                  _vm._v(_vm._s(albumResponse.pictureNumber) + " "),
                  _c("i", {
                    staticClass: "el-icon-picture",
                    staticStyle: { color: "#6298d0" },
                  }),
                ]
              ),
              _c("br"),
              _c("span", { staticStyle: { "margin-left": "5px" } }),
              _c(
                "span",
                {
                  staticClass: "demonstration",
                  staticStyle: { float: "right", "margin-right": "10px" },
                },
                [
                  _vm._v(
                    _vm._s(_vm._f("formatDateTime")(albumResponse.createdDate))
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    background: "#67c23a",
                    color: "white",
                    "font-size": "20px",
                    "text-align": "center",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        background: "#67c23a",
                        "margin-left": "10px",
                        border: "none",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.download1(albumResponse.id)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "white" } }, [
                        _vm._v("Tải xuống"),
                      ]),
                      _vm._v("  \n          "),
                      _c("i", {
                        staticClass: "el-icon-download",
                        staticStyle: { color: "white" },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }